<template>
    <!-- 排名 -->
    <div class="rank-wrap">
        <!--头部样式开始-->
        <div class="rank-header">
            <van-image  fit="cover" custom-class="ranking-bg"  :src="require('../../../assets/images/user/ranking-bg.png')" />
            <img class="ranking-bg-icon" src="~assets/images/user/ranking-bg-icon.png"/>
            <div class="header-text-box">
                <div class="header-tit">排行榜</div>
                <div class="rank-text" v-if="teammateInfo.userRank && teammateInfo.userRank !== 1">距离NO.1你还差一丢丢</div>
            </div>

            <!--头部内容开始-->
            <div class="header-cont">
                <div class="vertical-start">
                    <img class="header-avatar" :src="userInfo.avatar ? userInfo.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" />
                    <div class="header-info">
                        <div class="header-user vertical-center">
                            <div class="name white-space">{{ userInfo.nickname ? userInfo.nickname :'酸谈社群' }}</div>
                            <div class="leave">Lv{{ userInfo.level }}</div>
                            <div class="serial-number" v-if="userInfo && userInfo.number">编号:{{userInfo.number}}</div>
                        </div>
                        <div class="statistics-cont vertical-center">
                            <div class="statistics-list vertical-center">
                                <span class="statistics-icon">积分</span>
                                <span class="statistics-num">{{ $handle.tranNumber(userInfo.amount_credit,3) }}</span>
                            </div>
                            <div class="statistics-list vertical-center">
                                <span class="statistics-icon">经验值</span>
                                <span class="statistics-num">{{ $handle.tranNumber(userInfo.experience,3) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="schedule-box">
                    <div class="schedule-cont">
                        <div class="vertical-center space-between">
                            <span class="leave-num theme-color">Lv{{ userInfo.level }}</span>
                            <span class="leave-num theme-color" v-if="userInfo.is_max === 0">Lv{{ userInfo.next_level }}</span>
                        </div>
                        <div class="leave-schedule">
                            <div class="leave-schedule-bg" :style="{width: 100 +'%'}" v-if="userInfo.is_max === 1"></div>
                            <div class="leave-schedule-bg" :style="{width: leaveScheduleBg +'%'}" v-else></div>
                        </div>
                    </div>
                    <div class="schedule-hint" >
                        <div v-if="userInfo.is_max === 0">
                            还差<span class="theme-color">{{ userInfo.next_level_difference }}</span>经验值升级
                        </div>
                    </div>
                </div>

            </div>
            <!--头部内容结束-->
        </div>
        <!--头部样式结束-->

        <!--导航样式开始-->
        <div class="screen-nav align-center">
            <div class="screen-nav-item vertical-center">
                <div class="screen-nav-list theme-color" :class="choiceRanking === 'team' ? 'screen-nav-list-act' : ''" @click="switchRanking('team')" v-if="userInfo.team_id">队排行榜</div>
                <div class="screen-nav-list theme-color" :class="(choiceRanking === 'all' || userInfo.team_id === 0) ? 'screen-nav-list-act' : ''" @click="switchRanking('all')">总排行榜</div>
            </div>
        </div>
        <!--导航样式结束-->

        <!--用户详情开始-->
        <div class="user-info" v-if="teammateInfo.userRank !== 1">
            <div class="rank-con">
                <div class="rank-num">{{ teammateInfo.userRank }}</div>

                <img class="user-avatar" :src="userInfo.avatar ? userInfo.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" />
                <div class="info-box">
                    <div class="info-top vertical-center">
                        <div class="team-tit white-space">{{ userInfo.nickname ? userInfo.nickname :'酸谈社群' }}</div>
                        <div class="serial-number" v-if="userInfo && userInfo.number">编号:{{userInfo.number}}</div>
                    </div>
                    <div class="info-bot vertical-center">
                        <div class="leave-tit">Lv{{ userInfo.level ? userInfo.level : 0 }}</div>
                        <div class="integral-box vertical-center">
                            <img src="~assets/images/index/task.png"/>
                            <span>{{ userInfo.experience }}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="rank-info vertical-center">
                <div class="rank-up flex">
                            <div class="flex" v-if="teammateInfo.up !== 0">
                                <div class="type flex">与上一名差</div>
                                <div class="num theme-color flex">{{ teammateInfo.up ? $handle.tranNumber(teammateInfo.up,3)  :0 }}</div>经验值</div>
                    <div class="flex" v-if="teammateInfo.up !== 0 && teammateInfo.down !== 0">，</div>
                    <div class="flex" v-if="teammateInfo.down !== 0">
                                比下一名多<div class="num rank-down flex">{{  teammateInfo.down ? $handle.tranNumber(teammateInfo.down,3) : 0 }}</div>经验值
                            </div>
                </div>
            </div>
        </div>
        <!--用户详情结束-->

        <!--排名列表开始-->
        <div class="rank-item">
            <div class="rank-list vertical-center" v-for="(item,index) in teammateInfo.data" :key="item.id">
                <div class="rank-num">{{ index+1 }}</div>
                <div class="user-avatar-box">
                    <img class="user-avatar" :src="(item.wechat && item.wechat.avatar) ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" />
                    <img v-if="index < 3" :src="require('../../../assets/images/user/first'+index+'-icon.png')" class="tit-icon"/>
                </div>
                <div class="list-info">
                    <div class="serial-name-box vertical-center">
                      <div class="name white-space">{{ (item.wechat && item.wechat.nickname) ? item.wechat.nickname : '酸谈社群' }}</div>
                      <div class="serial-number" v-if="item.profile && item.profile.number">编号:{{item.profile.number}}</div>
                    </div>
                    <div class="info-box vertical-center">
                        <div class="leave-tit">Lv{{ (item.profile.rule ? item.profile.rule.detail : 0) && item.profile.rule.detail.name }}</div>
                        <div class="experience-box vertical-center">
                            <img src="~assets/images/index/task.png"/>
                            <span class="experience-num">{{ $handle.tranNumber(item.experience,3) }}</span>
                        </div>
                    </div>
                </div>
            </div>
<!--            <BottomTip v-if="teammateInfo.data && teammateInfo.data.length > 4"></BottomTip>-->
        </div>
        <!--排名列表结束-->

    </div>
</template>

<script>
import BottomTip from "components/BottomTip";

export default {
    name: "ranking",
    data(){
        return {
            members: [],   //队友排行信息
            teammateInfo:{},
            choiceRanking: 'team',
            leaveScheduleBg: '',
        }
    },
    created() {
        if(this.$route.params.id){
            this.getTeammateInfo(this.userInfo.team_id)
        }
        else {
            this.getTeammateInfo()
        }

    },
    methods:{
        getTeammateInfo(teanId = ''){
            let _this = this
            let url
            if (teanId === ''){
                url = _this.$api.Userranks
            }else {
                url = _this.$api.Userranks + "?team_id=" + teanId
            }
            _this.$http.get(url,true).then(res => {
                if (res.data.success){
                    _this.teammateInfo = res.data.data
                    // this.leaveScheduleBg = (this.userInfo.experience - this.userInfo.current_level_experience)/(this.userInfo.next_level_experience - this.userInfo.current_level_experience) * 100 <= 100 ? (this.userInfo.experience - this.userInfo.current_level_experience)/(this.userInfo.next_level_experience - this.userInfo.current_level_experience) * 100 : 100
                    let experience = this.userInfo.experience - this.userInfo.current_level_experience
                    let next_experience = this.userInfo.next_level_experience - this.userInfo.current_level_experience
                    if ((experience / next_experience) < 0){
                        this.leaveScheduleBg = 100
                    }else if((experience / next_experience) > 1){
                        this.leaveScheduleBg = 100

                    } else {
                        this.leaveScheduleBg = (experience / next_experience) * 100
                    }
                    if(parseFloat(this.userInfo.next_level_experience) == 0){
                        this.leaveScheduleBg = 100;
                    }else {
                        let speed_num = (parseFloat(this.userInfo.experience) / parseFloat(this.userInfo.next_level_experience))*100;
                        if(speed_num > 100){
                            speed_num = 100
                        }else if(speed_num < 0){
                            speed_num = 0
                        }
                        this.leaveScheduleBg = speed_num
                    }
                }
            })
        },
        switchRanking(type){
            if (this.userInfo.team_id === 0){
                return
            }
            this.teammateInfo = {}
            if (type === 'team'){
                this.choiceRanking = 'team'
                this.getTeammateInfo(this.$route.params.id)
            }else if (type === 'all'){
                this.choiceRanking = 'all'
                this.getTeammateInfo()
            }
        }

    },
    watch:{
        userInfo(val, oldVal){
            return val;
        }
    },
    computed:{
        userInfo(){
            return this.$store.getters.userInfo;
        }
    },
    components:{
        BottomTip
    }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/ranking/ranking.scss";
</style>